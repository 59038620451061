import { formatDate } from "../../shared/utility";

export default function CardVacationRow(props) {
  const { info } = props;

  return (
    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
      <div className="flex justify-between gap-x-4 py-3">
        <dt className="text-gray-500">{info.name}</dt>
        <dd className="text-gray-700">
          <time dateTime={info.start_date}>{formatDate(info.start_date)}</time>{" "}
          - <time dateTime={info.end_date}>{formatDate(info.end_date)}</time>
        </dd>
      </div>
    </dl>
  );
}
