import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import AbsenceRequestForm from "../../components/forms/absence-requests/AbsenceRequestForm";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";

export function AbsenceRequestsViewModel(logout) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [absences, setAbsences] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const user_id = localStorage.getItem("id") * 1;
  const getMyAbsences = async function (search, page, startDate, endDate) {
    setLoading(true);
    const authData = {
      action: "fetchEmployeeAbsences",
      userId: user_id,
      employee_id: user_id,
      search: search,
      pageNumber: page,
      start_date: startDate,
      end_date: endDate,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setAbsences(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createAbsence = async function (data) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: user_id },
      "multipart/form-data",
      "absences/absence-day/createAbsence.php"
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getMyAbsences(null, 0);
      setSuccessNotification({
        show: true,
        title: "Absence request created",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddAbsenceSlideOver = () => {
    setSlideOver({
      childComponent: (
        <AbsenceRequestForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createAbsence}
        />
      ),
      open: true,
    });
  };

  const editAbsence = async function (data, index) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: user_id },
      "multipart/form-data",
      "absences/absence-day/editAbsence.php"
    );
    if (callResult.success) {
      const currentAbsences = [...absences.data];
      const updateAbsence = {
        ...data,
        start_date: data.start_date.toISOString(),
        end_date: data.end_date.toISOString(),
        reasonText: callResult.data.reasonText,
      };

      const updatePayload = {
        index: index,
        item: updateAbsence,
      };

      const updatedAbsences = updateObjectInArray(
        currentAbsences,
        updatePayload
      );

      const newAbsences = { ...absences, data: updatedAbsences };

      setAbsences(newAbsences);
      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteAbsenceCall = async function (absence_id) {
    const authData = {
      action: "deleteAbsence",
      absence_id: absence_id,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      getMyAbsences(null, 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setConfirmationModalOpen({
      open: false,
      childComponent: null,
    });
  };

  const deleteAbsenceConfirm = (absence_id) => {
    console.log("hey2");
    setConfirmationModalOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage={"Delete"}
          confirmAction={() => deleteAbsenceCall(absence_id)}
          setOpen={setConfirmationModalOpen}
          title={"Are you sure you want to delete absence request?"}
        />
      ),
      open: true,
    });
  };

  const openEditAbsenceSlideOver = (absence, index) => {
    setSlideOver({
      childComponent: (
        <AbsenceRequestForm
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          absence={absence}
          actionCall={editAbsence}
        />
      ),
      open: true,
    });
  };

  return {
    confirmationModalOpen,
    getMyAbsences,
    loading,
    absences,
    openAddAbsenceSlideOver,
    openEditAbsenceSlideOver,
    deleteAbsenceConfirm,
    selectedDate,
    setSelectedDate,
    setSlideOver,
    slideOver,
    setConfirmationModalOpen,
    setSuccessNotification,
    successNotification,
  };
}
