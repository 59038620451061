import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

export function DashboardViewModel(logout) {
  const [openDocuments, setOpenDocuments] = useState([]);
  const [vacations, setVacations] = useState([]);

  const fetchEmployeesForDashboard = async function () {
    const authData = {
      action: "fetchEmployeeOpenDocumentsForDashboard",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setOpenDocuments(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchVacationsForDashboard = async function () {
    const authData = {
      action: "fetchEmployeeVacationsForDashboard",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setVacations(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    fetchEmployeesForDashboard,
    fetchVacationsForDashboard,
    openDocuments,
    vacations,
  };
}
