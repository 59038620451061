import React from "react";

export default function TextAreaInput(props) {
  const {
    classes,
    label,
    name,
    onChange,
    value,
    error,
    explanation,
    ...other
  } = props;

  return (
    <div>
      <div className="flex space-x-2">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      </div>
      <div className="mt-1">
        <textarea
          name={name}
          onChange={onChange}
          value={value}
          labelOn={true}
          rows={4}
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
        />
      </div>
    </div>
  );
}
