import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem("authToken", authData.authToken);
  localStorage.setItem("fname", authData.fname);
  localStorage.setItem("lname", authData.lname);
  localStorage.setItem("email", authData.email);
  localStorage.setItem("id", authData.id);
  localStorage.setItem("userType", authData.userType);

  return {
    type: actionTypes.AUTH_SUCCESS,
    authToken: authData.authToken,
    fname: authData.fname,
    lname: authData.lname,
    email: authData.email,
    id: authData.id,
    userType: authData.userType,
  };
};

export const updateAccountSuccess = (user) => {
  localStorage.setItem("fname", user.fname);
  localStorage.setItem("lname", user.lname);
  localStorage.setItem("email", user.email);

  return {
    type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
    fname: user.fname,
    lname: user.lname,
    email: user.email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const requestFailed = (error) => {
  return {
    type: actionTypes.REQUEST_FAILED,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("fname");
  localStorage.removeItem("lname");
  localStorage.removeItem("email");
  localStorage.removeItem("id");
  localStorage.removeItem("userType");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const payload = {
      action: "login",
      email: email,
      password: password,
    };
    let url = config.url.API_URL + "apiEndPoint.php";

    axios
      .post(url, payload)
      .then((response) => {
        console.log("ok success2");
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(authSuccess(response.data));
      })
      .catch((err) => {
        console.log("ok success3");
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {

      const authData = {
        authToken: localStorage.getItem("authToken"),
        fname: localStorage.getItem("fname"),
        lname: localStorage.getItem("lname"),
        email: localStorage.getItem("email"),
        id: localStorage.getItem("id"),
        userType: localStorage.getItem("userType"),
      };

      console.log("authData", authData);

      dispatch(authSuccess(authData));
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};

export const updateAccountDetails = (payload) => {
  return (dispatch) => {
    dispatch(authStart());

    let url = config.url.API_URL + "users/" + localStorage.getItem("id");

    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      },
    };

    axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(updateAccountSuccess(payload));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};
