import { useEffect } from "react";
import { DashboardViewModel } from "../../viewmodels/dashboard/DashboardViewModel";
import { PaperClipIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import { ClockIcon } from "@heroicons/react/20/solid";
import DashboardCard from "./DashboardCard";
import CardVacationRow from "./CardVacationRow";
import CardInformationRow from "./CardInformationRow";

function Dashboard(props) {
  const { logout } = props;
  const {
    fetchEmployeesForDashboard,
    fetchVacationsForDashboard,
    openDocuments,
    vacations,
  } = DashboardViewModel(logout);

  useEffect(() => {
    fetchEmployeesForDashboard();
    fetchVacationsForDashboard();
  }, []);

  return (
    <div className="my-10">
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
        <DashboardCard
          card={{
            name: "Documents to fill",
            icon: PaperClipIcon,
            iconForeground: "text-teal-700",
            iconBackground: "bg-teal-100",
            data: openDocuments,
            infoRow: CardInformationRow,
            mainHref: "/my-documents",
          }}
        />
        <DashboardCard
          card={{
            name: "Vacations",
            icon: ClockIcon,
            iconForeground: "text-teal-700",
            iconBackground: "bg-teal-100",
            data: vacations,
            infoRow: CardVacationRow,
            mainHref: "/my-vacation-requests",
          }}
        />
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
