import { Link } from "react-router-dom";
import { classNames } from "../../shared/utility";

import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

export default function DashboardCard(props) {
  const { card } = props;

  return (
    <li className="overflow-hidden rounded-xl border border-gray-200">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <span
          className={classNames(
            card.iconBackground,
            card.iconForeground,
            "inline-flex rounded-lg p-3 ring-4 ring-white"
          )}
        >
          <card.icon className="h-6 w-6" aria-hidden="true" />
        </span>
        <div className="text-sm font-medium leading-6 text-gray-900">
          {card.name}
        </div>
        <Link className="relative ml-auto" to={card.mainHref}>
          <ArrowUpRightIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
      </div>
      {card.data.length > 0 ? (
        card.data.map((info) => <card.infoRow info={info} key={card.id} />)
      ) : (
        <p className="text-center mt-5">No {card.name} </p>
      )}
    </li>
  );
}
