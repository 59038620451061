import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import DocumentDetailsForm from "../../components/forms/my-documents/DocumentDetailsForm";

export function MyDocumentsViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getMyDocuments = async function (search, page) {
    console.log("page", page);
    setLoading(true);
    const authData = {
      action: "fetchEmployeeDocuments",
      employee_id: user_id,
      search: search,
      pageNumber: page,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setDocuments(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const submitAssignedDocument = async function (values, index) {
    const payload = {
      ...values,
      action: "submitAssignedDocument",
      user_id: user_id,
    };
    let callResult = await makePostApiCall(
      payload,
      "multipart/form-data",
      "assignedDocuments/assignedDocument/completeDocument.php"
    );
    if (callResult.success) {
      const currentDocuments = [...documents.data];
      const updateDocument = {
        ...values,
        status: 2,
        status_text: "completed",
      };

      const updatePayload = {
        index: index,
        item: updateDocument,
      };

      const updatedDocuments = updateObjectInArray(
        currentDocuments,
        updatePayload
      );

      const newDocuments = { ...documents, data: updatedDocuments };

      setDocuments(newDocuments);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setSuccessNotification({
        show: true,
        title: "Document uploaded",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAssignDocumentDocumentSlideOver = (document, index) => {
    setSlideOver({
      childComponent: (
        <DocumentDetailsForm
          index={index}
          setOpen={setSlideOver}
          document={document}
          hideName={true}
          actionCall={submitAssignedDocument}
        />
      ),
      open: true,
    });
  };

  return {
    getMyDocuments,
    loading,
    documents,
    openAssignDocumentDocumentSlideOver,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  };
}
