const prod = {
  url: {
    API_URL: "https://employee-api.eurobutcher.ca/api/",
  },
};

const dev = {
  url: {
    API_URL: "https://employee-api.eurobutcher.ca/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
