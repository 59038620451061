import { useState } from "react";

import Dropzone from "react-dropzone";

import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function ImportMediaDropzone(props) {
  const { fileName, handleInputChange } = props;

  const [error, setError] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);

  return (
    <div className="mt-2">
      <div>
        <Dropzone
          onDrop={(acceptedFile) => {
            if (acceptedFile.length > 0) {
              handleInputChange(convertToDefEventPara(fileName, acceptedFile));
              setUploadedFileName(acceptedFile[0].name);
              console.log("acceptedFile", acceptedFile[0].name);
            } else {
              setError("Could not upload file, please try again.");
            }
          }}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className:
                  "relative block w-full bg-blue-50 border-2 border-blue-300 border-solid rounded-lg p-5 text-center hover:border-gray-400 cursor-pointer",
              })}
            >
              <input {...getInputProps()} />
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center"></div>
                <p className="sm:hidden mt-3 text-gray-700 family-didact">
                  <span className="font-semibold text-blue-500">
                    Tap here to upload.
                  </span>
                </p>
                <p className="hidden sm:block mt-3 text-gray-700 family-didact">
                  Drag and drop file here, or click to select file
                </p>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className={classNames(uploadedFileName ? "p-4" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-gray-500">
          Uploaded file:{" "}
          <span className="text-gray-900">{uploadedFileName}</span>
        </h3>
      </div>
      <div className={classNames(error ? "text-center p-4" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">{error}</h3>
      </div>
    </div>
  );
}
