import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import VacationRequestForm from "../../components/forms/vacation-days/MyVacationRequestForm";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";

export function MyVacationsRequestViewModel(logout) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [vacations, setVacations] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const user_id = localStorage.getItem("id") * 1;
  const getMyVacations = async function (search, page, startDate, endDate) {
    setLoading(true);
    const authData = {
      action: "fetchEmployeeVacationDays",
      userId: user_id,
      employee_id: user_id,
      search: search,
      pageNumber: page,
      start_date: startDate,
      end_date: endDate,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setVacations(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createVacation = async function (data) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: user_id },
      "multipart/form-data",
      "vacation-days/vacation-day/createVacationDay.php"
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getMyVacations(null, 0);
      setSuccessNotification({
        show: true,
        title: "Vacation request created",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddVacationSlideOver = () => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createVacation}
        />
      ),
      open: true,
    });
  };

  const editVacation = async function (data, index) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: user_id },
      "multipart/form-data",
      "vacation-days/vacation-day/editVacationDay.php"
    );
    if (callResult.success) {
      const currentVacations = [...vacations.data];
      const updateVacation = {
        ...data,
        start_date: data.start_date.toISOString(),
        end_date: data.end_date.toISOString(),
      };

      const updatePayload = {
        index: index,
        item: updateVacation,
      };

      const updatedVacations = updateObjectInArray(
        currentVacations,
        updatePayload
      );

      const newVacations = { ...vacations, data: updatedVacations };

      setVacations(newVacations);
      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteVacationDayCall = async function (vacation_id) {
    const authData = {
      action: "deleteVacationDay",
      vacation_id: vacation_id,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      getMyVacations(null, 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setConfirmationModalOpen({
      open: false,
      childComponent: null,
    });
  };

  const deleteVacationDayConfirm = (vacation_id) => {
    console.log("hey2");
    setConfirmationModalOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage={"Delete"}
          confirmAction={() => deleteVacationDayCall(vacation_id)}
          setOpen={setConfirmationModalOpen}
          title={"Are you sure you want to delete vacation request?"}
        />
      ),
      open: true,
    });
  };

  const openEditVacationSlideOver = (vacation, index) => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          vacation={vacation}
          actionCall={editVacation}
        />
      ),
      open: true,
    });
  };

  return {
    confirmationModalOpen,
    getMyVacations,
    loading,
    vacations,
    openAddVacationSlideOver,
    openEditVacationSlideOver,
    deleteVacationDayConfirm,
    selectedDate,
    setSelectedDate,
    setSlideOver,
    slideOver,
    setConfirmationModalOpen,
    setSuccessNotification,
    successNotification,
  };
}
