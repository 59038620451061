import { ChevronRightIcon } from "@heroicons/react/20/solid";

import {
  ChartPieIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname, userType) {
  var title;
  var routes;

  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "My Vacation requests",
      href: "/my-vacation-requests",
      icon: UsersIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Absence requests",
      href: "/absence-requests",
      icon: UsersIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "My Documents",
      href: "/my-documents",
      icon: UsersIcon,
      current: false,
      showInMenu: true,
    },
  ];

  routes.forEach((route) => {
    route.open = false;
    if (route.children) {
      route.children.forEach((child) => {
        child.current = false;
        if (child.href === pathname) {
          route.open = true;
          child.current = true;

          title = child.name;
        }
      });
    } else if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
