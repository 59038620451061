import { useState } from "react";

import { makeGetApiCall } from "../../shared/makeApiCall";

export function MyAccountViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [receivingInvoices, setReceivingInvoices] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getReceivingInvoices = async function (search, page) {
    console.log("page", page);
    setLoading(true);
    let callResult = await makeGetApiCall(
      `receivingInvoices?page=${page}&search=${search}`
    );
    setLoading(false);
    if (callResult.success) {
      setReceivingInvoices(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    getReceivingInvoices,
    loading,
    receivingInvoices,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  };
}
