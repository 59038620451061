import { format, isValid, parseISO } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export function insertItem(array, action) {
  let newArray = array.slice();
  newArray.splice(action.index, 0, action.item);
  return newArray;
}

// needs the index and the item attributes itself
export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const getLastItem = (thePath) =>
  thePath.substring(thePath.lastIndexOf("/") + 1);

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const formatDate = (date) => {
  const parsedDate = parseISO(date);
  if (isValid(parsedDate)) {
    return format(parsedDate, "LLL dd, YYY");
  }
  return date;
};

export const convertToDefEventPara = (name, value) => {
  return {
    target: {
      name,
      value,
    },
  };
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ingestBarcodeScan(scannedValue) {
  console.log("scannedValue", scannedValue);
  // 01 90061741049614 11 231116 3102 003801 21 6151488150
  // 0190061741049614112311163102003801216151488150
  const plu = scannedValue.substring(2, 16);
  var date = scannedValue.substring(18, 24);
  date =
    date.substring(4, 6) +
    "-" +
    date.substring(2, 4) +
    "-" +
    date.substring(0, 2);

  var weight = scannedValue.substring(28, 34);
  weight = parseInt(weight, 10) / 100;

  const serial = scannedValue.substring(36, scannedValue.length);

  const info = { plu: plu, date: date, weight: weight, serial: serial };
  return info;
}
